import React from 'react';
import './App.css';
import Layout from './Components/Layout/Layout';
import ScrollToTop from './Components/ScrollToTop/scrolltotop';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Layout />
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App;
