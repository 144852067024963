import React from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { IMGIX_PATH } from "../../../BConfig";

const f1 = IMGIX_PATH + "f1_GoASmhwrx.svg";
const f2 = IMGIX_PATH + "f2_4HW1qxh7cV.svg";
const f3 = IMGIX_PATH + "f3_BD0RN37ol.svg";

const Footer = () => {
  return (
    // <!-- footer -->
    <div className={classes.footer} id="footer-con">
      <footer>
        <div className="container bg-footer">
          <div className="">
            <div className="">
              <div className="row">
                <div className={"col-md-12 " + classes.footerPolicies}>
                  <ul className={"list-unstyled " + classes.footerList}>
                    {/* <li className="border-right">
                                            <Link className="text-white" to="/fair-practice-code"><span className={classes.cDash}></span>Fair Practice Code</Link>
                                        </li>                                        
                                        <li className="border-right">
                                            <Link className="text-white" to="/privacy-policy"><span className={classes.cDash}></span>Privacy Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/ombudsman-scheme"><span className={classes.cDash}></span>Ombudsman Scheme</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/interest-rate-policy"><span className={classes.cDash}></span>Interest Rate Policy </Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/grievance-policy"><span className={classes.cDash}></span>Grievance Redressal Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <a href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Corporate+Social+Responsibility+Policy.pdf" rel="noopener noreferrer" target="_blank" className="text-white"><span className={classes.cDash}></span>Corporate Social Responsibility Policy</a>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/moratorium"><span className={classes.cDash}></span>Policy on EMI Moratorium</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/corporate-governance-policy"><span className={classes.cDash}></span>Corporate Governance Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/vigilance-policy"><span className={classes.cDash}></span>Vigilance Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/nomination-remuneration"><span className={classes.cDash}></span>Nomination & Remuneration Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/related-party-transaction-policy"><span className={classes.cDash}></span>Related Party Transaction Policy</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/familiarization-programme"><span className={classes.cDash}></span>Familiarization Programme</Link>
                                        </li>
                                        <li className="border-right">
                                            <Link className="text-white" to="/dlai-code-of-conduct"><span className={classes.cDash}></span>DLAI code of conduct</Link>
                                        </li> */}
                    <li className="border-right">
                      <Link className="text-white" to="/security-center">
                        <span className={classes.cDash}></span>Security Center
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to="/terms-and-conditions">
                        <span className={classes.cDash}></span>Terms and
                        Conditions
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to="/unauthorized-app">
                        <span className={classes.cDash}></span>Unauthorized App
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to="/grievance-redressal">
                        <span className={classes.cDash}></span>Grievance Redressal
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to="/lsp-dla">
                        <span className={classes.cDash}></span>LSPs and DLA
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to={{
                        pathname: "/",
                        state: { hash: "aboutSection" },
                      }}>
                        <span className={classes.cDash}></span>About Us
                      </Link>
                    </li>
                    <li className="border-right">
                      <Link className="text-white" to={{
                        pathname: "/",
                        state: { hash: "contactSection" },
                      }}>
                        <span className={classes.cDash}></span>Contact Us
                      </Link>
                    </li>
                    <li >
                      <Link className="text-white" to={{
                        pathname: "/",
                        state: { hash: "blogsSection" },
                      }}>
                        <span className={classes.cDash}></span>Blog
                      </Link>
                    </li>
                    {/* <li>
                                            <a href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Grievance+Redressal+Mechanism-Ex+Gratia.pdf" rel="noopener noreferrer" target="_blank" className="mr-0 text-white">Grievance Redressal Mechanism - Ex gratia</a>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <hr className="text-white" />

            {/* <!-- footer subtext --> */}
            <div className={classes.footerContacts}>
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className={"text-white " + classes.footerTextWhite}>
                    <img src={f1} alt="f1" />
                    <span>
                      <a
                        href="mailto:reachus@kbnbfc.in"
                        className={"text-black " + classes.footerReachUs}
                      >
                        {" "}
                        &nbsp;reachus@kbnbfc.in
                      </a>
                    </span>
                    <img src={f2} alt="f2" className={classes.footerImgf2} />
                    <span className={classes.footerNum}> 080-44292555 / 080-68534555 </span>
                    &nbsp;

                    <span>
                      <div style={{display:'inline-block', marginTop: '0.6rem' }}>
                        <img src={f3} alt="f3" className={classes.footerImgf3} />
                        <span className={classes.footerLoc}>
                          {" "}
                          Bengaluru - India
                        </span>
                      </div>
                    </span>

                  </div>
                </div>
                <div className="col-md-6">
                  <span className="copyright text-white">
                    <div className="pull-right">
                      <p className="text-white">
                        Krazybee Services Private Limited is a NBFC registered
                        with the RBI
                        {/* Copyright  &copy; 2019 KrazyBee NBFC.     */}
                      </p>
                      <p style={{ marginTop: '-1.5rem' }}>
                        CIN: U65100KA2016PTC086990
                      </p>
                    </div>
                  </span>
                </div>

              </div>
            </div>
            {/* <!-- end footer subtext --> */}
          </div>
        </div>

        {/* <!-- sub footer --> */}
        <div className="container-fluid bg-copyright">
          <div className="container-fluid"></div>
        </div>
        {/* <!-- end sub footer --> */}

        {/* <!-- end footer --> */}
      </footer>
    </div>
  );
};

export default Footer;
