import React from 'react';

import Header from './Header/Header';
import Hoc from '../../hoc/Hoc';
import Footer from './Footer/Footer';
import Routes from '../../routes'





const Layout = () => {
    return (
        <Hoc>
            <Header />           
            <Routes />
            <Footer />
        </Hoc >
    );
}

export default Layout; 