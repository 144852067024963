export const PERSONAL_LOAN = 'personal-loan';
export const FAST_CASH = 'fast-cash';
export const FLEXI_PERSONAL_LOAN = 'flexi-personal-loan';
export const QUICK_LOAN = 'quick-loan';
export const MICRO_LOAN = 'short-term-working-capital';
export const SALARY_ON_DEMAND = 'salary-on-Demand';
export const INSTANT_LOAN = 'instant-loan';
export const SEMESTER_LOAN = 'semester-loan';
export const TWO_WHEELER_LOAN = 'two-wheeler-loan';
export const ECOMMERCE_LOAN = 'online-purchase-loan';
export const PERSONAL_LOAN_SELF_EMPLOYED = 'personal-loan-for-self-employed';
export const PERSONAL_LOAN_FOR_SALARIED = 'personal-loan-for-salaried';
export const PURCHASE_ON_EMI = 'purchase-on-emi';
export const LOAN_AGAINST_PROPERTY = 'loan-against-property';
export const BusinessLoan = 'business-loan';
export const PERSONAL_LOAN_UNSECURED = 'personal-loan-unsecured'
export const TOPUPLOAN = 'top-up-loan'
// export const GOLD_LOAN = 'gold-loan'