import styled from 'styled-components';
import { MDBNavbar, MDBNavItem, MDBNavbarBrand, MDBNavbarNav } from "mdbreact";
import { Link } from 'react-router-dom';

export const NavLinkRouter = styled(Link)`
    color: #000;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        color: rgba(0,0,0,0.7);
    }
`
export const MDBNavbarNavCustom = styled(MDBNavbarNav)`
    margin-right: 20px;
`
export const MDBMainNav = styled(MDBNavbar)`
    width: 100%;
    position:fixed !important;
    top:0;
    z-index:2;
`
export const MDBNavbarBrandLogo = styled(MDBNavbarBrand)`
    margin-left:3rem;
`
export const MDBNavItemLink = styled(MDBNavItem)`
    color: #ece9e9;
    font-size: 16px;
    min-width: fit-content;
    text-align: center;
    font-family: montserrat;
    font-weight: 500;
    text-transform: none;
    margin: 5px 20px;
    @media screen and (max-width:1280px){
        margin: 5px 3px;
    }
    @media screen and (max-width:768px){
        text-align: left;
    }
`
export const NavLink = styled.a`
    color:#000;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        color:#000;
    }
`
export const DorpDownLink = styled.a`
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #000;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover{
        text-decoration: none;
    }
`
export const LoanProductDropDown = styled.div`
    display : flex;
    width:  12em;
    flex-direction : column;
    background-color: white;
    height : auto;
    padding : 0.5em;
    font-size : medium;
`;

export const PersonalDropDown = styled.div`
    display : flex;
    flex-direction : column;
    background-color: white;
    @media screen and (max-width:768px){
        display : none;
    }
`;

export const LoanDropDown = styled.div`
    display : ${(props) => props.Display};
    position: absolute;
    flex-direction : column;
    background-color: white;
    width : 18em;
    left -18em;
    border-radius : 5px;
    border : 1px lightgrey solid;
    @media screen and (max-width:768px){
        display : none;
    }
`;

export const PersonalMobViewDropDown = styled.div`
    display : none;
    @media screen and (max-width:768px){
        display : flex;
        background-color: white;
        flex-direction : column;
    }
`;

export const MobFlexInline = styled.div`
    @media screen and (max-width:768px){
        display : flex-inline;
        flex-direction : row;
        background-color: white;
        font-size: medium;
    }
`;

export const PersonalLoan = styled.span`
        font-size: small;
        color: rgba(0,0,0,0.7);
`;